/** @jsx jsx */
import React from "react"
import { css, jsx } from "@emotion/core"
import SiteLayout from "../components/layouts/siteLayout.js"
import { Link } from "gatsby"

import AwesomeSlider from "react-awesome-slider"
import withAutoplay from "react-awesome-slider/dist/autoplay"
import "react-awesome-slider/dist/styles.css"
import Button from "../components/button"

import Events from "../components/events/event"

import ClaxtonFlags from "../images/claxton-flags.jpg"
import Poultry from "../images/poultry-plant.jpg"
import FruitCake from "../images/claxton-fruitcake.jpg"
import Womboles from "../images/ga-fruitcake.png"
import CRCTownHall from "../images/CRCTownHallFlyer.png"
import July4th from "../images/HaganJuly4thFlyer2024.jpg"
import Halloween from "../images/2024CruisinRockandRollFlyer.png"
import FallPic from "../images/NDPFlyer.jpg"

const AutoplaySlider = withAutoplay(AwesomeSlider)

function Tile(props) {
  return (
    <div
      css={{
        width: "100%",
        backgroundColor: "#fff",
        boxShadow: "0px 2px 9px -4px #383838",
        padding: "25px",
        marginTop: "35px",
        "@media (min-width: 1000px)": {
          width: `100%`,
          order: props.order,
          ...props.additionalDtStyles,
        },
        "@media (min-width: 1400px)": {
          order: "1",
          width: "100%",
          maxWidth: `590px`,
        },
      }}
    >
      {props.children}
    </div>
  )
}

const IndexPage = () => (
  <SiteLayout padding="0">
    <div
      css={{
        position: "relative",
      }}
    >
      <div
        css={{
          height: "550px",
          backgroundColor: "#fff",
        }}
      >
        <AutoplaySlider
          fillParent="true"
          play={true}
          cancelOnInteraction={false}
          interval={6000}
          bullets={false}
        >
          <div data-src={ClaxtonFlags} />
          <div data-src={Poultry} />
          <div data-src={FruitCake} />
          <div data-src={Womboles} />
        </AutoplaySlider>
      </div>
    </div>
    <div
      css={{
        marginTop: "2px",
        padding: "1rem",
        backgroundColor: "#fff",
        boxShadow: "0px 2px 9px -4px #383838",
        fontSize: "2.25rem",
        textAlign: "center",
      }}
    >
      <p>
        Our Mission is to promote and support business, civic, cultural, and
        educational growth in our community.
      </p>
    </div>

    {/* ------------- EVENTS SECTION ------------- */}
    <div
      css={{
        padding: "32px",
        display: "grid",
        flexWrap: "wrap",
        justifyContent: `center`,
        "@media (min-width: 1000px)": {
          display: `grid`,
          gridTemplateColumns: `1fr 1fr 1fr`,
          gridGap: `2rem`,
          rowGap: `2rem`,
        },
      }}
    >
      {/* ----- CALENDAR ----- */}
      <Tile
        additionalDtStyles={{
          gridRowStart: `1`,
          gridRowEnd: `3`,
        }}
      >
        <h3
          css={{ marginBottom: "0.667rem", borderBottom: "1px dotted #abadb3" }}
        >
          Upcoming Events
        </h3>
        <div
          css={{
            display: `flex`,
            //height: `90%`,
            flexDirection: `column`,
            justifyContent: `space-between`,
          }}
        >
          <Events limit="8" />
          <div>
            <Button
              to="about/calendar"
              text="View Full Calendar"
              css={{ alignSelf: `end` }}
            />
          </div>
        </div>
      </Tile>

        <Tile
        additionalDtStyles={{
          gridRowStart: `1`,
          gridRowEnd: `2`,
        }}
      >
        <h2
          css={{
            marginBottom: "0.667rem",
            borderBottom: "1px dotted #abadb3",
            textDecoration: `none !important`,
            color: `#333333`,
          }}
        >
          Featured Event
        </h2>
        <div
          css={{
            //display: `none`,
            "@media (min-width: 1400px)": {
              display: `block`,
              textAlign: `center`,
              overflow: `hidden`,
            },
          }}
        >
          <img css={{ width: `90%`, padding: `0 0` }} src={CRCTownHall} />
        </div>
      </Tile>
              <Tile
        additionalDtStyles={{
          gridRowStart: `1`,
          gridRowEnd: `2`,
        }}
      >
        <h2
          css={{
            marginBottom: "0.667rem",
            borderBottom: "1px dotted #abadb3",
            textDecoration: `none !important`,
            color: `#333333`,
          }}
        >
          Featured Event
        </h2>
        <div
          css={{
            //display: `none`,
            "@media (min-width: 1400px)": {
              display: `block`,
              textAlign: `center`,
              overflow: `hidden`,
            },
          }}
        >
          <img css={{ width: `90%`, padding: `0 0` }} src={July4th} />
        </div>
      </Tile>


      <Tile
        additionalDtStyles={{
          gridRowStart: `1`,
          gridRowEnd: `2`,
        }}
      >
      <Link to="/programs/cruisin-in-the-country" css={{textDecoration: `none !important`}}> 
        <h2
          css={{
            marginBottom: "0.667rem",
            borderBottom: "1px dotted #abadb3",
            textDecoration: `none !important`,
            color: `#333333`,
          }}
        >
          Featured Event
        </h2>
        <div
          css={{
            //display: `none`,
            "@media (min-width: 1400px)": {
              display: `block`,
              textAlign: `center`,
              overflow: `hidden`,
            },
          }}
        >
          <img css={{ width: `90%`, padding: `0 0` }} src={Halloween} />
        </div>
        </Link> 
      </Tile>

      {/* <Tile additionalDtStyles={{
        gridRowStart: `1`,
        gridRowEnd: `2`
      }}>
       {/*-- <Link to="/programs/claxton-wildlife-festival" css={{textDecoration: `none !important`}}> -- */}
      {/*<h2
          css={{ marginBottom: "0.667rem", borderBottom: "1px dotted #abadb3", textDecoration: `none !important`, color: `#333333` }}
        >
          Featured Event
        </h2>
        <div
          css={{
            //display: `none`,
            "@media (min-width: 1400px)": {
              display: `block`,
              textAlign: `center`,
              overflow: `hidden`,
            },
          }}
        >
          <img css={{width: `90%`, padding: `0 0`}} src={FallPic} />
        </div>
        {/*-- </Link> -- */}
      {/*-- </Tile> */}
    </div>
  </SiteLayout>
)

export default IndexPage
